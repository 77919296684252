import request from '../utils/request'

export function login(params) {
    if (!params) {
        params = {
            UserNum: '',
            Password: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/Login?UserNum=" + params.UserNum + "&Password=" + params.Password;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
