<template>
    <div id="login">
        <img
            class="logo"
            @click="isShow = '0'"
            style=""
            src="../../assets/bowinzlogo.png"
            alt="logo"
        />
        <div class="cont">
            <transition name="bounce">
                <div class="content" v-if="isShow === '0'">
                    <p>登录管理系统</p>
                    <div class="main">
                        <a-input v-model="username" placeholder="请输入您的用户名">
                            <i slot="prefix" class="iconfont icon">&#xe8c8;</i>
                        </a-input>
                    </div>
                    <div class="main">
                        <a-input
                            v-model="password"
                            type="password"
                            placeholder="请输入您的密码"
                        >
                            <i slot="prefix" class="iconfont icon">&#xe60f;</i>
                        </a-input>
                    </div>
                    <span class="forget-password" @click="isShow = '1'">忘记密码？</span>
                    <div class="main1">
                        <el-button type="warning" size="medium" @click="goLogin">
                            登录
                        </el-button>
                    </div>
                </div>
            </transition>
            <transition name="bounce">
                <div class="content" v-if="isShow === '1'">
                    <p>找回密码</p>
                    <div class="main">
                        <a-input v-model="usernum" placeholder="请输入您的用户名">
                            <i slot="prefix" class="iconfont icon">&#xe8c8;</i>
                        </a-input>
                    </div>
                    <div class="main">
                        <a-input v-model="email" placeholder="请输入您的邮箱">
                            <i slot="prefix" class="iconfont icon">&#xe605;</i>
                        </a-input>
                    </div>
                    <div class="main1">
                        <el-button type="warning" size="medium" @click="forgotPassword">
                            确认
                        </el-button>
                    </div>
                </div>
            </transition>
        </div>
        <!-- <transition name="bounce">
                        <div class="content" v-if="isShow==='1'">
                            <p>找回密码</p>
                            <div class="main">
                                <el-input
                                    prefix-icon="el-icon-user-solid"
                                    placeholder="请输入你的账号"
                                    v-model="username"
                                    clearable>
                                </el-input>
                                &lt;!&ndash;                    <img class="user_icon" src="../../assets/20221123170121.png" alt=""/>&ndash;&gt;
                            </div>
                            <div class="main">
                                <el-input
                                    prefix-icon="el-icon-user-solid"
                                    placeholder="请输入你的邮箱"
                                    v-model="username"
                                    clearable>
                                </el-input>
                                &lt;!&ndash;                    <img class="user_icon" src="../../assets/20221123170121.png" alt=""/>&ndash;&gt;
                            </div>
                            <div class="main1">
                                <el-button type="warning" size="medium"
                                           @click="goLogin">
                                    确认
                                </el-button>
                            </div>
                        </div>
                    </transition> -->
    </div>
</template>

<script>
import {login} from "../../api/login";
import {ForgotPassword} from "../../api/Employee";

export default {
    name: "login",
    data() {
        return {
            isShow: "0",
            loading: "",
            username: "",
            password: "",
            usernum: "",
            email: "",
        };
    },
    mounted() {
        // 绑定enter事件
        this.enterKeyup();
    },
    destroyed() {
        // 销毁enter事件
        this.enterKeyupDestroyed();
    },
    methods: {
        enterKey(event) {
            if (event.keyCode === 13) {
                if (this.isShow == 0) {
                    this.goLogin();
                }
                if (this.isShow == 1) {
                    this.forgotPassword();
                }
            }
        },
        enterKeyupDestroyed() {
            document.removeEventListener("keyup", this.enterKey);
        },
        enterKeyup() {
            document.addEventListener("keyup", this.enterKey);
        },
        openLoading() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        goLogin() {
            this.openLoading();
            if (this.username === "") {
                this.loading.close();
                this.$message.warning("请输入用户名！");
            } else if (this.password === "") {
                this.loading.close();
                this.$message.warning("请输入密码！");
            } else {
                const param = {
                    UserNum: this.username,
                    Password: this.password,
                };
                login(param)
                    .then((res) => {
                        // console.log(res)
                        if (res.result.isModified) {
                            this.loading.close();
                            console.log(res.result)
                            for (let i of res.result.moduleTypes) {
                                for (let j of i.lSystemModuleRights) {
                                    if (j.children.length === 0) {
                                        delete j.children
                                    } else {
                                        for (let k of j.children) {
                                            if (k.children.length === 0) {
                                                delete k.children
                                            } else {
                                                for (let l of k.children) {
                                                    if (l.children.length === 0) {
                                                        delete l.children
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            res.result.moduleTypes[0].isModified = true
                            localStorage.setItem("AdminUser", JSON.stringify(res.result));
                            if(res.result.moduleTypes[0].lSystemModuleRights[0].children){
                              this.$router.push({name: res.result.moduleTypes[0].lSystemModuleRights[0].children[0].fLink});
                            }else{
                              this.$router.push({name: res.result.moduleTypes[0].lSystemModuleRights[0].fLink});
                            }
                            // this.$router.push({name: 'FillIn'})
                        } else {
                            this.loading.close();
                            this.$message.error("用户登录信息错误！");
                        }
                        // console.log(res.result)
                    })
                    .catch((err) => {
                        if (err.message === "timeout of 6000ms exceeded") {
                            this.$message.error("登录超时，请联系管理员");
                        }
                        this.loading.close();
                        console.log(err);
                    });
            }
        },
        forgotPassword() {
            this.openLoading();
            ForgotPassword({
                fUserNum: this.usernum,
                Email: this.email,
            }).then((res) => {
                if (res.result !== "00000000-0000-0000-0000-000000000000") {
                    this.$message.success("请查看邮件");
                    this.loading.close();
                    this.isShow = "0";
                } else {
                    this.$message.error("请输入正确的用户名和邮箱");
                    this.loading.close();
                }
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
#login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-image: url('../../assets/20221123152934.png');
    background-size: 100% 100%;
    padding: 30px 200px 0 30px;
    box-sizing: border-box;
    justify-content: space-between;

    .logo {
        width: 160px;
        height: 100px;
    }

    .cont {
        height: 100%;
        display: grid;
        align-items: center;
    }

    .content {
        width: 504px;
        height: 460px;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(120, 120, 120, 0.1);
        padding: 40px 42px 30px 42px;
        grid-area: 1 / 1 / 2 / 2;

        p {
            font-size: 32px;
            font-weight: bold;
            color: rgb(253, 126, 22);
            margin-left: 22px;
        }

        .forget-password {
            font-size: 18px;
            font-weight: bold;
            color: #F07106;
            float: right;
            cursor: pointer;
            margin-top: 20px;
        }

        .main {
            width: 100%;
            height: 60px;
            box-sizing: border-box;
            margin-top: 40px;

            .user_icon {
                width: 27px;
                height: 27px;
                font-size: 18px;
                position: relative;
                top: -43px;
                left: 28px;
            }
        }

        .main1 {
            width: 100%;
            height: 60px;
            box-sizing: border-box;
            margin-top: 76px;
        }

        .remind {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            padding: 0 30px;
            box-sizing: border-box;
            font-size: 14px;
            color: #ff8000;
            text-shadow: 2px 2px 3px #999;
        }
    }
}

.el-input >>> .el-input__inner, .el-input .el-input__clear {
    border: none !important;
    background: #FAFAFA !important;
    padding-left: 80px !important;
    padding-right: 70px;
    height: 64px;
    font-size: 18px;
    color: #999999;
}

.el-button {
    width: 100%;
    height: 64px;
    font-size: 22px;
    background-color: rgb(255, 103, 31);
    border-radius: 44px;
}

.bounce-enter-active {
    animation: bounce-in 0.8s;
}

.bounce-leave-active {
    animation: bounce-in 0.8s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

/deep/ .ant-input {
    background: #FAFAFA;
    border-radius: 8px;
    padding-left: 70px !important;
    height: 64px;
    font-size: 18px;
    color: #999999;
    border: none !important;
    box-sizing: border-box;
}

.icon {
    font-size: 30px;
    color: #333333;
    margin-left: 10px;
}

.icon1 {
    font-size: 30px;
    color: #333333;
    cursor: pointer;
}
</style>